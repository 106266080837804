import { getNumberedEnv, isHostNumberedEnv } from "@utils/url-utils";

export const appVersion: string = "1.0.0-feature-ST-3005-make-tokens-feature-available-in-prod-if-floorplan-is-enabled.2";
export const appTimestamp: string = "2025-04-01T12:14:57.000Z";
export const commitId: string = "a25ee30b467ae9a6849202a113266cec436403bb";
export const commitUrl: string = "https://dev.azure.com/faro-connect/Stellar/_git/Dashboard/commit/a25ee30b467ae9a6849202a113266cec436403bb?refName=refs%2Fheads%2Fmaster";
export const pullRequestId: string = "32236";
export const pullRequestUrl: string = "https://dev.azure.com/faro-connect/Stellar/_git/Dashboard/pullrequest/32236";
export const jiraTicketId: string = "ST-3005";
export const jiraTicketUrl: string = "https://faro01.atlassian.net/browse/ST-3005";

export const sentryDSNKey =
  "https://c9b011b3368bc41e188fcbae7fb7a872@o381590.ingest.sentry.io/4505674738499584";
export const localizeKey = "iKNI0OJMw3RLe";
export const muiProKey =
  // eslint-disable-next-line max-len -- this is a license key
  "3d69a08fc3c9d75a1ff22bebf8fcfd23Tz05NjEyMyxFPTE3NTUyNDI3NzUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==";
export const amplitudeStagingKey = "6669713f5f821a32accec4f56cde20d9";
export const amplitudeProdKey = "0f55bafc3d3b678c50ca73a88964be8a";

export const procoreApiUrlStaging = "https://sandbox.procore.com/rest/v1.0";
export const procoreApiUrlProduction = "https://api.procore.com/rest/v1.0";

export const isNumberedEnv = isHostNumberedEnv(globalThis.location.host);
export const numberedEnv = isNumberedEnv
  ? getNumberedEnv(globalThis.location.host)
  : null;
